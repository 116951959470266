type formatTaxnumberProps = {
  taxNumber: string
  country?: string
}

const formatTaxNumber = ({ taxNumber, country = 'AU' }: formatTaxnumberProps) => {
  if (country === 'AU') {
    const formatAUTaxNumber =
      [...(taxNumber || '').replace(/\s/g, '')]
        .reverse()
        .join('')
        .match(/.{1,3}/g)
        ?.join(' ') || ''

    return [...formatAUTaxNumber].reverse().join('')
  } else if (country === 'NZ') {
    const formatNZTaxNumber =
      [...(taxNumber || '').replace(/\s/g, '')]
        .join('')
        .match(/.{1,3}/g)
        ?.join(' ') || ''

    return [...formatNZTaxNumber].join('')
  } else if (country === 'GB') {
    const cleanTaxNumber = (taxNumber || '').replace(/\s/g, '')
    // Ensure the VAT number starts with "GB" and format as per "GB 320 7485 71"
    if (cleanTaxNumber.startsWith('GB')) {
      const numericPart = cleanTaxNumber.slice(2)
      // Extract parts of the numeric portion according to the UK format
      const partOne = numericPart.slice(0, 3) // First 3 digits
      const partTwo = numericPart.slice(3, 7) // Next 4 digits
      const partThree = numericPart.slice(7) // Last 2 digits
      return `GB ${partOne} ${partTwo} ${partThree}`
    } else {
      // If not starting with "GB", format assuming it's just the numeric part
      const partOne = cleanTaxNumber.slice(0, 3) // First 3 digits
      const partTwo = cleanTaxNumber.slice(3, 7) // Next 4 digits
      const partThree = cleanTaxNumber.slice(7) // Last 2 digits
      return `${partOne} ${partTwo} ${partThree}`
    }
  } else {
    return taxNumber
  }
}

export default formatTaxNumber
